import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";
import '../../assets/sass/people.scss'

export default function PeoplePage() {


    return (
        <div id="people-page">
            <Navbar active={'people'}/>
            <PageHeaderSection title={'people'}/>

            <section className="mb-5">
                <div className="container">

                    <div className="display-2 fw-normal text-black py-5 lh-1">
                        MEET THE PEOPLE <br/>
                        WHO ARE ALWAYS <br/>
                        WANDERING.
                    </div>

                    <div className="people-wrapper">
                        <div className="row gx-3">
                            <h1 className="display-1"> COMING SOON</h1>

                            <div className="col-lg-4 col-md-6 mb-3">
                                {/*<Person*/}
                                {/*    id="person-1"*/}
                                {/*    image={employeeImg3}*/}
                                {/*    name="Coming Soon"*/}
                                {/*    position="Senior Account Director"*/}
                                {/*>*/}
                                {/*    <p>*/}
                                {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur distinctio*/}
                                {/*        hic iusto quo sunt? Accusamus asperiores assumenda at cumque deleniti dolorum et*/}
                                {/*        laudantium provident quas veniam. Aliquam animi asperiores iusto?*/}
                                {/*    </p>*/}
                                {/*</Person>*/}
                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}