export default function AccordionItem({id, parentId, header, pdfLink, children}) {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                {pdfLink ? (
                    <a
                        className="text-black fw-bold fs-4 border-top border-bottom-0 border-black accordion-button collapsed px-0"
                        href={pdfLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {header}
                    </a>
                ) : (
                    <button
                        className="text-black fw-bold fs-4 border-top border-bottom-0 border-black accordion-button collapsed px-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${id}`}
                        aria-expanded="false"
                        aria-controls={id}
                    >
                        {header}
                    </button>
                )}
            </h2>
            <div
                id={id}
                className="accordion-collapse collapse"
                data-bs-parent={`#${parentId}`}
            >
                <div className="accordion-body px-0">
                    {children}
                </div>
            </div>
        </div>
    );
}