import $ from "jquery";

export const togglePlayPauseHandler = (e) => {
    if (e.currentTarget.paused) {
        e.currentTarget.play();
        e.currentTarget.parentNode.classList.add('video__play');
        e.currentTarget.parentNode.classList.remove('video__pause');

    } else {
        e.currentTarget.pause();
        e.currentTarget.parentNode.classList.add('video__pause');
        e.currentTarget.parentNode.classList.remove('video__play');
    }
}

export const toggleSiblingPlayPauseHandler = e => {
    let video = $(e.currentTarget).siblings('video')[0];

    if (video) {
        if (video.paused) {
            video.play();
            video.parentNode.classList.add('video__play');
            video.parentNode.classList.remove('video__pause');

        } else {
            video.pause();
            video.parentNode.classList.add('video__pause');
            video.parentNode.classList.remove('video__play');
        }
    }

}



export const videoToggleMuteHandler = e => {
    let video = $(e.currentTarget).siblings('video')[0];
    if (video) {
        video.muted = (!video.muted);
        if (video.muted) {
            $(e.currentTarget)?.removeClass('ri-volume-up-line');
            $(e.currentTarget)?.addClass('ri-volume-mute-line');
        } else {
            $(e.currentTarget)?.removeClass('ri-volume-mute-line');
            $(e.currentTarget)?.addClass('ri-volume-up-line');
        }
    }
}