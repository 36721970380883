import {HashRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import PhilosophyPage from "./pages/PhilosophyPage";
import ApproachPage from "./pages/ApproachPage";
import GetToWanderPage from "./pages/GetToWanderPage";
import ProjectsPage from "./pages/ProjectsPage";
import NewsPage from "./pages/NewsPage";
import PeoplePage from "./pages/PeoplePage";
import CareersPage from "./pages/CareersPage";
import ContactPage from "./pages/ContactPage";
import ProjectPage from "./pages/ProjectPage";

export default function AppRouter() {
    return (
        <HashRouter>
            <Routes>
                <Route path={'/'} element={<HomePage/>}/>
                <Route path={'/philosophy'} element={<PhilosophyPage/>}/>
                <Route path={'/approach'} element={<ApproachPage/>}/>
                <Route path={'/get-to-wander'} element={<GetToWanderPage/>}/>
                <Route path={'/projects'} element={<ProjectsPage/>}/>
                <Route path={'/projects/:project'} element={<ProjectPage/>}/>
                <Route path={'/news'} element={<NewsPage/>}/>
                <Route path={'/people'} element={<PeoplePage/>}/>
                <Route path={'/careers'} element={<CareersPage/>}/>
                <Route path={'/contact'} element={<ContactPage/>}/>
            </Routes>
        </HashRouter>
    );
}