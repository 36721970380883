import {Link} from "react-router-dom";

export default function Menu({active}) {
    return (
        <>
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active === 'projects' ? 'active' : ''}`}
                        to={'/projects'}
                    >
                        PROJECTS
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link ${active === 'approach' ? 'active' : ''}`}
                        to={'/approach'}
                    >
                        APPROACH
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        className={`nav-link ${active === 'careers' ? 'active' : ''}`}
                        to={'/careers'}
                    >
                        CAREERS
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        className={`nav-link ${active === 'people' ? 'active' : ''}`}
                        to={'/people'}
                    >
                        PEOPLE
                    </Link>
                </li>
            </ul>
            <div className="navbar-nav">
                <div className="nav-item">
                    <Link
                        className={`nav-link ${active === 'contact' ? 'active' : ''}`}
                        to={'/contact'}
                    >
                        CONTACT US
                    </Link>
                </div>
            </div>
        </>
    );
}