import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";

export default function PhilosophyPage() {
    useScrollToTopOnMount();


    return (
        <div id="philosophy-page">
            <Navbar active={'philosophy'}/>
            <PageHeaderSection title={'philosophy'}/>

            <section className="text-center py-5">
                <div className="py-5">
                    <div className="text-uppercase font-parking fs-4">
                        WANDER IS A BRAND THAT
                    </div>

                    <div className="text-uppercase fs-1 lh-1 my-2">
                        UNDERSTANDS THE HEARTBEAT <br/>
                        OF ITS COMMUNITIES.
                    </div>

                    <div className="fs-5 lh-1">
                        With a curated brand portfolio that encapsulate <br/>
                        the essence of the each destination.
                    </div>
                </div>
            </section>


            <section className="text-center bg-accent-black text-white py-5">
                <div className="py-5">
                    <div className="text-uppercase font-parking fs-4">
                        OUR CURATED PORTFOLIO IS NOT JUST A MEDLEY OF BRANDS
                    </div>

                    <div className="text-uppercase fs-1 lh-1 my-2">
                        IT IS A STORYTELLING EXPERIENCE.
                    </div>

                    <div className="fs-5 lh-1">
                        Each destination is thoughtfully designed to become an integral<br/>
                        part of your journey.
                    </div>
                </div>
            </section>

            <section className="text-center py-5">
                <div className="py-5">
                    <div className="text-uppercase font-parking fs-4">
                        WANDER IS A PHILOSOPHY THAT
                    </div>

                    <div className="text-uppercase fs-1 lh-1 my-2">
                        BELIEVES IN THE INTIMATE CONNECTION <br/>
                        BETWEEN INDIVIDUALS AND PLACES.
                    </div>

                    <div className="fs-5 lh-1">
                        It's about weaving the essence of a person into the fabric of a location, creating a
                        symbiotic <br/>
                        relationship where the soul of the individual resonates with the soul of the place.
                    </div>
                </div>

            </section>


            <section className="text-center bg-accent-black text-white py-5">
                <div className="py-5">
                    <div className="text-uppercase font-parking fs-4">
                        WE BELIEVE THAT
                    </div>

                    <div className="text-uppercase fs-1 lh-1 my-2">
                        EVERY DESTINATION HAS A UNIQUE STORY TO <br/>
                        TELL, AND WE'RE HERE TO NARRATE IT.
                    </div>
                </div>

            </section>

            <section className="text-center py-5">
                <div className="py-5">
                    <div className="text-uppercase font-parking fs-4">
                        WANDER WHERE|
                    </div>

                    <div className="text-uppercase fs-1 lh-1 my-2">
                        THE SOUL OF PEOPLE <br/>
                        & PLACE INTERTWINE
                    </div>

                </div>

            </section>
            
            <div className="bg-accent-black py-2">
            </div>


            <Footer/>
        </div>
    );
}