import {Link} from "react-router-dom";
import "../../assets/sass/navbar.scss";
import Menu from "../components/Menu";
import WanderLogo from "../components/WanderLogo";

export default function Navbar({active = "home"}) {
    return (
        <nav
            id="app-navbar"
            className="navbar navbar-expand-lg px-lg-5 py-4 bg-accent-black"
            data-bs-theme="dark"
        >
            <div className="container-fluid py-1">
                <Link to={'/'} className="navbar-brand">
                    <WanderLogo/>
                </Link>

                <button
                    className="navbar-toggler border-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarMenu"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarMenu">
                    <Menu active={active}/>
                </div>
            </div>
        </nav>
    );
}