import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";

export default function GetToWanderPage() {


    return (
        <div id="wander-page">
            <Navbar active={'get_to_wander'}/>

            <PageHeaderSection title={'get to wander'}/>

            <section>
                <div className="container">
                    <div className="display-1 py-5 lh-1 text-black">
                        EXPERIENTIAL <br/>
                        PLATFORM
                    </div>
                </div>
            </section>
            
            <div className="bg-black py-2"></div>

            <Footer/>
        </div>
    );
}