import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'remixicon/fonts/remixicon.css';
import './assets/css/main.css';
import AppRouter from "./partials/AppRouter";
import $ from "jquery";
import {useEffect} from "react";

function App() {

    useEffect(() => {
        const windowScrollListener = function (event) {
            let scroll = Math.floor(this.scrollY);
            if (scroll > 300) {
                $('#go-top')?.addClass('show')
            } else {
                $('#go-top')?.removeClass('show');
            }

        }
        window.addEventListener("scroll", windowScrollListener);

        return () => {
            window.removeEventListener("scroll", windowScrollListener);
        }
    }, []);

    return (
        <div className="app-wrapper">
            <AppRouter/>
        </div>
    );
}

export default App;