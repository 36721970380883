export default function Logo() {
    return (
        <svg viewBox="0 0 213 213" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M106.31 212.16C47.94 212.16 0.449951 164.67 0.449951 106.3C0.449951 47.9299 47.94 0.439941 106.31 0.439941C164.68 0.439941 212.17 47.9299 212.17 106.3C212.17 164.67 164.68 212.16 106.31 212.16ZM106.31 10.4499C53.45 10.4499 10.45 53.4499 10.45 106.31C10.45 159.17 53.45 202.17 106.31 202.17C159.17 202.17 202.17 159.17 202.17 106.31C202.17 53.4499 159.17 10.4499 106.31 10.4499Z"
            />
            <path
                d="M171.93 61.82C170.27 57 166.37 53.09 160.34 50.21C155.56 47.92 149.54 46.39 143.42 45.9C135.12 45.24 102.9 47.3 95.3699 49.19C93.1599 49.74 91.73 51.82 92.17 53.83L92.72 56.33C93.03 57.75 94.18 58.81 95.72 59.09C96.24 59.18 96.78 59.18 97.33 59.09C106.5 57.42 147.39 53.36 154.44 54.66C154.81 54.73 155.03 54.8 155.16 54.85C164.51 58.73 159.33 73.07 157.62 79.99C156.43 84.79 145.77 125.11 141.93 140.48C141.81 140.97 141.06 142.68 141.26 143.17L113.32 75.57C112.75 74.19 111.32 73.28 109.67 73.26L103.58 73.16C102.13 73.14 100.75 73.78 99.8699 74.88C98.9899 75.98 98.77 77.36 99.27 78.57L101.6 84.22L56.81 157.49C52.05 155.4 51.95 152.22 52.89 145.75L52.97 145.25C54.82 132.29 75.5399 51.97 75.5399 51.97H61.77C58.12 66.52 45.9399 114.9 43.5399 124.55C41.5299 132.65 37.76 142.43 40.62 150.76C42.38 155.88 46.85 160.09 53.55 162.98L54.17 163.23C56.67 164.26 59.42 165.09 62.35 165.7C64.56 166.16 66.84 166.49 69.14 166.68C70.13 166.76 71.17 166.8 72.25 166.82C83.5 167 98.97 164.1 102.74 163.16C104.95 162.61 106.38 160.53 105.94 158.52L105.39 156.01C105.08 154.59 103.93 153.53 102.39 153.26C101.88 153.16 101.33 153.16 100.77 153.26C99 153.58 83.58 156.36 71.17 157.59L107.42 98.3L135.52 166.29L135.64 166.6H149.45C153.39 150.76 157.34 134.92 161.28 119.08C163.8 108.93 166.23 98.75 168.85 88.62C171.05 80.11 174.94 70.59 171.91 61.81L171.93 61.82Z"
            />
        </svg>
    );
}