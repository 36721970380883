import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";
import PageHeaderSection from "../sections/PageHeaderSection";
import careersImage from "../../assets/images/careers-image.jpg";
import './../../assets/sass/careers.scss'
import AccordionItem from "../components/AccordionItem";

export default function CareersPage() {
    useScrollToTopOnMount();

    return (
        <div id="careers-page">
            <Navbar active={'careers'}/>
            <PageHeaderSection title={'careers'}/>

            <section className="bg-accent-black text-white pb-5">
                <div className="container">
                    <div className="display-1 text-uppercase pt-1 pb-5">
                        WANDER IN THE PATH THAT IS MEANT FOR YOU
                    </div>

                    <div className="row">
                        <div className="col-lg-5 mb-3">
                            <div className="fs-3" style={{textAlign: 'justify'}}>
                                Calling all bold souls, ready to release the grip on their imaginations and
                                plunge into a sea of endless possibilities. We're on the lookout for you!
                                f your creative energy knows no bounds, if you speak in the language of
                                visuals and concepts, then you've landed where you truly belong. Big picture
                                thinkers, it's time.
                            </div>
                            <div className="display-3 fw-normal mt-5">
                                IF YOU ARE ONE, <br/>
                                GET IN TOUCH: <br/>
                                <a className="text-white linear-bg-effect text-break fs-2 fw-light text-decoration-none"
                                   href="mailto:careers@gettowander.com">careers@gettowander.com</a>
                            </div>

                        </div>
                        <div className="col-lg-7 px-5 mb-3">
                            <img src={careersImage} alt="careers" style={{width: '100%'}}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">

                            <div className="display-3 fw-bold text-black ">
                                OPEN POSITIONS
                            </div>
                            <div className="py-5 fs-2">
                                To apply, please click on the link <br/>
                                below each job description.
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="px-2">
                                <div
                                    id="accordion-careers"
                                    className="accordion accordion-flush border-top border-bottom border-black"
                                >
                                    <AccordionItem
                                        id="job-1"
                                        parentId='accordion-careers'
                                        header="Planner Engineer"
                                        pdfLink="/career/1.pdf"
                                    />

                                    <AccordionItem
                                        id="job-2"
                                        parentId='accordion-careers'
                                        header="Procurement and Tendering Engineer"
                                        pdfLink="/career/2.pdf"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}