import {Link} from "react-router-dom";
import {togglePlayPauseHandler, videoToggleMuteHandler} from "../../global/global";

export default function ProjectSection({heading, video, poster, children}) {

    return (
        <section className="project bg-accent-black">
            <div className="container">
                <h1 className="sub-heading display-3 text-white text-uppercase">{heading}</h1>
                <div className="content py-5">
                    {children}
                </div>
            </div>

            <div className="video-container position-relative">
                <i
                    onClick={videoToggleMuteHandler}
                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                    role="button"
                />
                <video
                    onClick={togglePlayPauseHandler}
                    className="w-100"
                    style={{maxHeight: '90vh'}}
                    src={video}
                    poster={poster}
                    autoPlay={true}
                    muted={true}
                    controls={false}
                    loop={true}
                    playsInline={true}
                    controlsList="nofullscreen"
                />
            </div>

            <div className="py-5 text-center">
                <Link to={'/projects'} className="text-decoration-none text-white display-5 text-uppercase">VIEW MORE
                    PROJECTS <strong>HERE</strong></Link>
            </div>

        </section>
    );
}