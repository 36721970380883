export default function WhoWeAreSection() {
    return (
        <section className="bg-accent-black text-accent-white">
            <div className="py-5">
                <div className="container text-center py-5">
                    <h3 className='fw-normal fs-3 text-uppercase'>WHO ARE WE?</h3>
                    <p className="fs-4">
                        We are a commercial developer that creates commercial destinations for various purposes. Covering
                        shopping, work, entertainment, learning, culture and hospitality in Egypt and the region through an
                        Integrated services of development strategy, design and build, and property management.

                    </p>
                </div>
            </div>
        </section>
    );
}