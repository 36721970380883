import {toggleSiblingPlayPauseHandler} from "../../global/global";

export default function VideoPlayIconSvg() {
    return (
        <svg className={"video-play-icon"} fill="none" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg" onClick={toggleSiblingPlayPauseHandler}>
            <path
                d="M64.9999 128.143C99.8727 128.143 128.143 99.8728 128.143 65C128.143 30.1272 99.8727 1.85714 64.9999 1.85714C30.1271 1.85714 1.85706 30.1272 1.85706 65C1.85706 99.8728 30.1271 128.143 64.9999 128.143Z"
                stroke="#F4F4F4" strokeWidth="3.71429"/>
            <path d="M94.7144 65.9286L46.4286 89.1428V42.7143L94.7144 65.9286Z" stroke="#F4F4F4" strokeWidth="3.71429"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}