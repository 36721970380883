import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";
import img1 from "../../assets/images/projects/01.jpg";
import img2 from "../../assets/images/projects/02.jpg";
import img3 from "../../assets/images/projects/03.jpg";
import img4 from "../../assets/images/projects/04.jpg";

export default function NewsPage() {


    return (
        <div id="news-page">
            <Navbar active={'news'}/>
            <PageHeaderSection title={'news'}/>

            <section className="py-5">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-xxl-4 col-lg-6 mb-3 ">
                            <div className="card border-0 rounded-0">
                                <img src={img1} className="" alt="..."/>
                                <div className="card-body px-0">
                                    <h4 className="card-title text-uppercase">GET TO WANDER PLATFORM</h4>
                                    <p className="card-text">
                                        Sketching a vision, constructing a dream,
                                        launching a game changer. The soulful
                                        destination that transformed the North Coast
                                        commercial scene, and Egypt at large.
                                        Lakeyard is a place that has a whole
                                        generation emotionally connected to. A
                                        project that became a category owner in the
                                        commercial world by holding its ground
                                        against emerging competition over the years.
                                    </p>

                                    <a
                                        href="javascript:void(0)"
                                        className="btn btn-outline-dark rounded-0 border-2 text-uppercase"
                                    >
                                        read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6 mb-3">
                            <div className="card border-0 rounded-0">
                                <img src={img2} className="" alt="..."/>
                                <div className="card-body px-0">
                                    <h4 className="card-title text-uppercase">GET TO WANDER PLATFORM</h4>
                                    <p className="card-text">
                                        Sketching a vision, constructing a dream,
                                        launching a game changer. The soulful
                                        destination that transformed the North Coast
                                        commercial scene, and Egypt at large.
                                        Lakeyard is a place that has a whole
                                        generation emotionally connected to. A
                                        project that became a category owner in the
                                        commercial world by holding its ground
                                        against emerging competition over the years.
                                    </p>

                                    <a
                                        href="javascript:void(0)"
                                        className="btn btn-outline-dark rounded-0 border-2 text-uppercase"
                                    >
                                        read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6 mb-3">
                            <div className="card border-0 rounded-0">
                                <img src={img3} className="" alt="..."/>
                                <div className="card-body px-0">
                                    <h4 className="card-title text-uppercase">GET TO WANDER PLATFORM</h4>
                                    <p className="card-text">
                                        Sketching a vision, constructing a dream,
                                        launching a game changer. The soulful
                                        destination that transformed the North Coast
                                        commercial scene, and Egypt at large.
                                        Lakeyard is a place that has a whole
                                        generation emotionally connected to. A
                                        project that became a category owner in the
                                        commercial world by holding its ground
                                        against emerging competition over the years.
                                    </p>

                                    <a
                                        href="javascript:void(0)"
                                        className="btn btn-outline-dark rounded-0 border-2 text-uppercase"
                                    >
                                        read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6 mb-3">
                            <div className="card border-0 rounded-0">
                                <img src={img4} className="" alt="..."/>
                                <div className="card-body px-0">
                                    <h4 className="card-title text-uppercase">GET TO WANDER PLATFORM</h4>
                                    <p className="card-text">
                                        Sketching a vision, constructing a dream,
                                        launching a game changer. The soulful
                                        destination that transformed the North Coast
                                        commercial scene, and Egypt at large.
                                        Lakeyard is a place that has a whole
                                        generation emotionally connected to. A
                                        project that became a category owner in the
                                        commercial world by holding its ground
                                        against emerging competition over the years.
                                    </p>

                                    <a
                                        href="javascript:void(0)"
                                        className="btn btn-outline-dark rounded-0 border-2 text-uppercase"
                                    >
                                        read more
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <div className="bg-black py-2"></div>
            <Footer/>
        </div>
    );
}

