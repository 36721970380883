import {Link} from "react-router-dom";
import '../../assets/sass/future-forward.scss';
import {useEffect, useRef} from "react";
import {gsap} from "gsap";

export default function FutureForwardSection() {

    const el1 = useRef();
    const el2 = useRef();
    const el3 = useRef();
    const el4 = useRef();


    useEffect(() => {
        const timeline = gsap.timeline();

        timeline.fromTo(el1.current, 1.4, {
            rotate: -50,
            xPercent: -50,
            opacity: 0
        }, {
            rotate: 0,
            xPercent: 0,
            opacity: 1,
            ease: 'bounce'
        });

        timeline.to(el1.current, {
            opacity: 0,
            duration: .6
        }, "+=1.5")

        timeline.repeat(-1);


        timeline.fromTo(el2.current, 1.4, {
            xPercent: 50,
            opacity: 0
        }, {
            xPercent: 0,
            opacity: 1,
            ease: 'bounce'
        });

        timeline.to(el2.current, {
            opacity: 0,
            duration: .6
        }, "+=1.5")

        timeline.fromTo(el3.current, 1.4, {
            yPercent: 50,
            opacity: 0
        }, {
            yPercent: 0,
            opacity: 1,
            ease: 'bounce'
        });

        timeline.to(el3.current, {
            opacity: 0,
            duration: .6
        }, "+=1.5")


        timeline.fromTo(el4.current, 1.4, {
            scale: 0.1,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            ease: 'bounce'
        });

        timeline.to(el4.current, {
            opacity: 0,
            duration: .6
        }, "+=1.5")

        timeline.repeat(-1);

        return () => {
            timeline.kill();
        }

    }, []);


    return (
        <section className="future-forward bg-accent-black overflow-hidden">
            
            <div className="text-center fs-3 text-uppercase text-accent-white">we are</div>

            <div className="container-fluid">
                <div className="future-forward-animation-wrapper mb-3">
                    <div className="animation-item" ref={el1}>
                        human-centric
                    </div>

                    <div className="animation-item" ref={el2}>
                        experiential
                    </div>

                    <div className="animation-item" ref={el3}>
                        future <br/> forward
                    </div>

                    <div className="animation-item" ref={el4}>
                        collaborative
                    </div>
                </div>
            </div>

            <div className="container position-relative" style={{zIndex: '99999999999'}}>
                <div className="fs-5 fw-normal text-uppercase text-center text-accent-white mb-3">
                    <Link className="text-white text-decoration-none " to={'/approach'}>
                        <span className="me-2">find out more</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.591" height="13.572"
                             viewBox="0 0 15.591 13.572">
                            <path
                                  d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                  transform="translate(0 0)" fill="#f4f4f4"/>
                        </svg>

                    </Link>
                </div>
            </div>
        </section>
    );
}