import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import PageHeaderSection from "../sections/PageHeaderSection";

export default function ContactPage() {


    return (
        <div id="contact-page">
            <Navbar active={'contact'}/>
            <PageHeaderSection title={'contact us'}/>

            <section className="bg-accent-black text-white">
                <div className="container">

                    <div className="display-1 text-uppercase mb-3">
                        HELLO, <br/>
                        LET’S TALK OR MEET.
                    </div>
                    <div className="py-5">
                        <div className="mb-3 fs-2">
                            If you have a question: <br/>
                            <a className="text-primary linear-bg-effect fs-3 fw-light text-decoration-none"
                               href="mailto:hello@gettowander.com">hello@gettowander.com</a>
                        </div>

                        <div className="mb-3 fs-2">
                            If you want to work with us: <br/>
                            <a className="text-primary linear-bg-effect fs-3 fw-light text-decoration-none"
                               href="mailto:careers@gettowander.com"> careers@gettowander.com</a>
                        </div>

                        <div className="mb-3 fs-2">
                            If you want to talk business: <br/>
                            <a className="text-primary linear-bg-effect fs-3 fw-light text-decoration-none"
                               href="mailto:aly.farid@gettowander.com">aly.farid@gettowander.com</a>
                            <br/>
                            <a className="text-primary linear-bg-effect fs-3 fw-light text-decoration-none"
                               href="tel:+201119949788">T +201119949788</a>
                        </div>

                        <div className="mb-3 fs-2">
                            If you are meeting us, we’re at: <br/>
                            <p className="fs-4 fw-light">
                                Office B3, Katameya Heights Business Center, <br/>
                                New Cairo, Cairo, Egypt.
                            </p>
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="mb-5 fs-1 text-capitalize">
                            follow us
                        </div>
                        <div className="fs-1 text-capitalize lh-sm">

                            <a className="text-white fw-light linear-bg-effect text-decoration-none"
                               href="https://www.instagram.com/gettowander/" target="_blank"
                               rel="noreferrer">Instagram</a> <br/>

                            <a className="text-white fw-light linear-bg-effect text-decoration-none"
                               href="https://www.facebook.com/profile.php?id=61555325387876" target="_blank"
                               rel="noreferrer">Facebook</a> <br/>
                            <a className="text-white fw-light linear-bg-effect text-decoration-none"
                               href="https://www.linkedin.com/company/wander-development/" target="_blank"
                               rel="noreferrer">LinkedIn</a> <br/>
                        </div>
                    </div>


                </div>
            </section>

            <Footer/>
        </div>
    );
}