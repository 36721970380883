import Logo from "../components/Logo";
import '../../assets/sass/page-header.scss';

export default function PageHeaderSection({title}) {
    return (
        <section className="page-header bg-accent-black text-white pb-4">
            <div className="container">
                <div className="logo">
                    <Logo/>
                </div>
                <div className="">
                    <h1 className="display-5 fw-normal text-uppercase text-center m-0">
                        {title}
                    </h1>
                </div>
            </div>
        </section>
    );
}