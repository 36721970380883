import Navbar from "../sections/Navbar";
import HeroSection from "../sections/HeroSection";
import WhatWeDoSection from "../sections/WhatWeDoSection";
import DestinationsSection from "../sections/DestinationsSection";
import FutureForwardSection from "../sections/FutureForwardSection";
import Footer from "../sections/Footer";
import WhoWeAreSection from "../sections/WhoWeAreSection";
import useScrollToTopOnMount from "../../hooks/useScrollToTopOnMount";

export default function HomePage() {
    useScrollToTopOnMount();

    return (
        <div id="home-page">
            <Navbar/>
            <HeroSection/>
            <WhatWeDoSection/>
            <WhoWeAreSection/>
            <DestinationsSection/>
            <FutureForwardSection/>
            <Footer/>
        </div>
    )
}