import LakeyardVideo from "../../assets/videos/Lakeyard.mp4";
import LakeyardPoster from "../../assets/videos/Lakeyard-poster.jpg";
import LightHouseVideo from "../../assets/videos/Lighthouse.mp4";
import LightHousePoster from "../../assets/videos/Lighthouse-poster.jpg";
import NorthedVideo from "../../assets/videos/Northed.mp4";
import NorthedPoser from "../../assets/videos/Northed-poster.jpg";
import GoodDaysVideo from "../../assets/videos/GoodDays.mp4";
import GoodDaysPoster from "../../assets/videos/GoodDays-poster.jpg";
import MatchaVideo from "../../assets/videos/Matcha.mp4";
import MatchaPoster from "../../assets/videos/Matcha-poster.jpg";
import {Link} from "react-router-dom";
import '../../assets/sass/destinations.scss';
import VideoDetailsLink from "../components/VideoDetailsLink";
import {togglePlayPauseHandler, videoToggleMuteHandler} from "../../global/global";
import VideoPlayIconSvg from "../components/VideoPlayIconSvg";

export default function DestinationsSection() {

    return (
        <section className="our-destinations">

            <div className="display-5 text-center text-uppercase fw-normal text-accent py-3">
                our destinations
            </div>

            <div className="container">
                <div className="row gx-0 mb-3 flex-lg-row-reverse">

                    <div className="col-lg-4 mb-3 mb-lg-0 d-flex align-items-center justify-content-center">
                        <a
                            href="https://www.instagram.com/gettowander/"
                            target="_blank"
                            rel="noreferrer"
                            className="p-3 d-flex align-items-center text-center text-decoration-none color-black"
                            style={{backgroundColor: "#f1f1f1"}}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center bg-black rounded-5 me-2 "
                                style={{width: '60px', height: '60px'}}>
                                <i className="fs-2  ri-instagram-line text-white"/>
                            </div>
                            <span className="text-capitalize">get to wander</span>
                        </a>
                    </div>

                    <div className="col-lg-8">
                        <div className="video-outer">
                            <div className="video position-relative video__play">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={LakeyardVideo}
                                    poster={LakeyardPoster}
                                    autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline={true}
                                    controlsList="nofullscreen"
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'LAKEYARD'} linkPath={'/projects/Lakeyard'}/>
                        </div>
                    </div>
                </div>

                <div className="row gx-3">
                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={LightHouseVideo}
                                    poster={LightHousePoster}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline={true}
                                    controlsList="nofullscreen"
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'THE LIGHTHOUSE'} linkPath={'/projects/Lighthouse'}/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    style={{height: "360px", background: "#000"}}
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={NorthedVideo}
                                    poster={NorthedPoser}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline={true}
                                    controlsList="nofullscreen"
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'NORTHED'} linkPath={'/projects/Northed'}/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    style={{height: "360px", background: "#000"}}
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={GoodDaysVideo}
                                    poster={GoodDaysPoster}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline={true}
                                    controlsList="nofullscreen"
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'Good Days'} linkPath={'/projects/GoodDays'}/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="video-outer">
                            <div className="video position-relative">
                                <i
                                    onClick={videoToggleMuteHandler}
                                    className="position-absolute top-0 left start-0 ri-volume-mute-line px-1 d-inline-block bg-black text-white z-1"
                                    role="button"
                                />
                                <video
                                    style={{height: "360px", background: "#000"}}
                                    onClick={togglePlayPauseHandler}
                                    className="w-100"
                                    src={MatchaVideo}
                                    poster={MatchaPoster}
                                    // autoPlay={true}
                                    muted={true}
                                    controls={false}
                                    loop={true}
                                    playsInline={true}
                                    controlsList="nofullscreen"
                                />
                                <VideoPlayIconSvg/>
                            </div>
                            <VideoDetailsLink name={'Matcha'} linkPath={'/projects/Matcha'}/>
                        </div>
                    </div>

                </div>

                <div className="p-5 text-center">
                    <Link
                        to={`/projects`}
                        className="text-decoration-none fw-normal px-4 btn btn-outline-dark rounded-0 text-uppercase btn-lg py-3 bg-accent-black text-accent-white"
                    >
                        view all projects
                    </Link>
                </div>
            </div>
        </section>
    );
}